/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import {
  CompleteSeamanFragmentDoc,
  CompleteFlightBookingFragmentDoc,
  CompleteFlightBookingRefundFragmentDoc,
  VesselFieldsFragmentDoc,
  ParsedFlightOfferFieldsFragmentDoc,
} from './fragments.generated'
import { AirportFieldsFragmentDoc } from './airports-gql.generated'
import { CrewChangeOverviewFieldsFragmentDoc } from './crew-changes-gql.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type LoadCrewChangeSeamenForVesselQueryVariables = Types.Exact<{
  scheduleOrId?: Types.InputMaybe<Types.Scalars['UUID']['input']>
  vesselOrId: Types.Scalars['UUID']['input']
}>

export type LoadCrewChangeSeamenForVesselQuery = {
  crewChangeSeamenForVessel: Array<{
    id: string
    crewHash: string
    signOnDate: string
    signOffDate: string
    contractEndAt: string
    seamanId: string
    role: Types.CrewChangeRole
    sourceId: string | null
    crewChangeLinkage: { isLinked: boolean; bucketId: string | null; isTemporary: boolean }
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
      flightBookings: Array<{
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        switchForIdenticalCheaperOfferAutomatically: boolean
        switchForSimilarCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        finalOffer: {
          amount: number
          currency: Types.Currency
          exchangeRate: { ratesToUsd: unknown } | null
        } | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }>
      preferredAirport: {
        isHomeAirport: boolean
        airport: {
          id: string
          alternativeIatas: Array<string>
          city: string
          countryAlpha2: string
          iata: string
          name: string
          timezoneOlson: string | null
        }
      } | null
      seamanBooks: Array<{
        id: string
        bookNumber: string
        issueAt: string | null
        expiryAt: string | null
        isFlagStateSeamanBook: boolean
        nationalityAlpha2: string | null
      }>
      seamanPassports: Array<{
        id: string
        passportNumber: string
        passportIssuePlace: string | null
        passportNationalityIso: string
        passportIssueAt: string
        passportExpiryAt: string
      }>
      seamanVisas: Array<{
        id: string
        visaNumber: string
        visaName: string
        issueAt: string
        expiryAt: string
      }>
      seamanContracts: Array<{
        id: string
        hanseaticAssignmentId: number
        contractFrom: string
        contractUntil: string
      }>
      seamanRank: { name: string; sortOrder: number | null } | null
      seamanCommunications: Array<{ id: string; type: string; value: string | null }>
    }
    vessel: {
      id: string
      vesselScheduleSource: Types.VesselScheduleSource
      charterName: string
      imo: number
    } | null
  }>
}

export type LoadCrewChangeSeamenForRankQueryVariables = Types.Exact<{
  rankId: Types.Scalars['Int']['input']
}>

export type LoadCrewChangeSeamenForRankQuery = {
  crewChangeSeamenForRank: Array<{
    id: string
    crewHash: string
    signOnDate: string
    signOffDate: string
    contractEndAt: string
    seamanId: string
    role: Types.CrewChangeRole
    sourceId: string | null
    crewChangeLinkage: { isLinked: boolean; bucketId: string | null; isTemporary: boolean }
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
      flightBookings: Array<{
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        switchForIdenticalCheaperOfferAutomatically: boolean
        switchForSimilarCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        finalOffer: {
          amount: number
          currency: Types.Currency
          exchangeRate: { ratesToUsd: unknown } | null
        } | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }>
      preferredAirport: {
        isHomeAirport: boolean
        airport: {
          id: string
          alternativeIatas: Array<string>
          city: string
          countryAlpha2: string
          iata: string
          name: string
          timezoneOlson: string | null
        }
      } | null
      seamanBooks: Array<{
        id: string
        bookNumber: string
        issueAt: string | null
        expiryAt: string | null
        isFlagStateSeamanBook: boolean
        nationalityAlpha2: string | null
      }>
      seamanPassports: Array<{
        id: string
        passportNumber: string
        passportIssuePlace: string | null
        passportNationalityIso: string
        passportIssueAt: string
        passportExpiryAt: string
      }>
      seamanVisas: Array<{
        id: string
        visaNumber: string
        visaName: string
        issueAt: string
        expiryAt: string
      }>
      seamanContracts: Array<{
        id: string
        hanseaticAssignmentId: number
        contractFrom: string
        contractUntil: string
      }>
      seamanRank: { name: string; sortOrder: number | null } | null
      seamanCommunications: Array<{ id: string; type: string; value: string | null }>
    }
    vessel: {
      id: string
      vesselScheduleSource: Types.VesselScheduleSource
      charterName: string
      imo: number
    } | null
  }>
}

export type LoadCrewChangeSeamenForRanksQueryVariables = Types.Exact<{
  rankIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']
}>

export type LoadCrewChangeSeamenForRanksQuery = {
  crewChangeSeamenForRanks: Array<{
    rankId: number
    crewChangeSeamen: Array<{
      sourceId: string | null
      id: string
      crewHash: string
      signOnDate: string
      signOffDate: string
      contractEndAt: string
      seamanId: string
      role: Types.CrewChangeRole
      crewChangeLinkage: { isLinked: boolean; bucketId: string | null; isTemporary: boolean }
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
        flightBookings: Array<{
          id: string
          active: boolean
          monitored: boolean
          ticketLine: string | null
          comment: string | null
          pnr: string | null
          status: Types.FlightBookingStatus
          amadeusSessionId: string | null
          managedByTravelTeam: boolean
          isAmadeus: boolean
          lastTicketingTime: string | null
          lastTicketingTimeSource: Types.LastTicketingTimeSource
          pendingUpdatedByTravelAgentNotification: boolean
          switchForIdenticalCheaperOfferAutomatically: boolean
          switchForSimilarCheaperOfferAutomatically: boolean
          ticketedAt: string | null
          bucketId: string | null
          autoTicket: boolean
          travelOfficeConfigurationId: string | null
          flight: {
            id: string
            updatedAt: string
            createdAt: string
            flightHash: string
            majorityCarrier: string | null
            travelOfficeConfigurationId: string
            validatingCarrier: string | null
            segments: Array<{
              id: string
              flightNumber: number
              index: number
              departure: {
                timezoneOlson: string | null
                time: string
                name: string | null
                iata: string
                countryAlpha2: string | null
                city: string | null
                date: string
                terminal: string | null
              }
              arrival: {
                timezoneOlson: string | null
                time: string
                name: string | null
                iata: string
                countryAlpha2: string | null
                city: string | null
                date: string
                terminal: string | null
              }
              marketingCarrier: {
                id: string
                createdAt: string
                updatedAt: string
                iata: string
                name: string
                logo: string | null
              } | null
              operatingCarrier: {
                id: string
                createdAt: string
                updatedAt: string
                iata: string
                name: string
                logo: string | null
              } | null
            }>
            travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
          }
          offer: {
            id: string
            amount: number
            tax: number | null
            passengerType: Types.PassengerType | null
            currency: string
            createdAt: string
            updatedAt: string
            flightId: string
            amadeusSessionId: string | null
            segmentFares: Array<{
              availableSeats: number | null
              fareTypes: Array<Types.FareType>
              fareBasisCode: string
              flightSegmentId: string
              bookingClass: string | null
              baggageDetails: {
                freeBagAllowance: number | null
                freeBagAllowanceType: Types.FreeBagAllowanceType | null
              }
            }>
            rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
            minirules: {
              beforeDeparture: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              beforeDepartureNoShow: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              afterDeparture: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
              afterDepartureNoShow: {
                changeAllowed: boolean | null
                changeMaxPenaltyAmount: number | null
                refundAllowed: boolean | null
                refundMaxPenaltyAmount: number | null
                revalidationAllowed: boolean | null
                revalidationMaxPenaltyAmount: number | null
                isoTicketValidityDate: string | null
                taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
              } | null
            } | null
            taxDetails: Array<{
              amount: number
              code: string | null
              isNonRefundable: boolean | null
            }> | null
          }
          finalOffer: {
            amount: number
            currency: Types.Currency
            exchangeRate: { ratesToUsd: unknown } | null
          } | null
          seaman: {
            id: string
            firstName: string
            lastName: string
            nationalityIso: string
            rank: number | null
            birthday: string | null
            placeOfBirth: string | null
            firstVaccinationDate: string | null
            firstVaccinationBrand: string | null
            secondVaccinationDate: string | null
            secondVaccinationBrand: string | null
            updatedAt: string
          }
          ticketLineData: { documentNumber: string } | null
          splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
        }>
        preferredAirport: {
          isHomeAirport: boolean
          airport: {
            id: string
            alternativeIatas: Array<string>
            city: string
            countryAlpha2: string
            iata: string
            name: string
            timezoneOlson: string | null
          }
        } | null
        seamanBooks: Array<{
          id: string
          bookNumber: string
          issueAt: string | null
          expiryAt: string | null
          isFlagStateSeamanBook: boolean
          nationalityAlpha2: string | null
        }>
        seamanPassports: Array<{
          id: string
          passportNumber: string
          passportIssuePlace: string | null
          passportNationalityIso: string
          passportIssueAt: string
          passportExpiryAt: string
        }>
        seamanVisas: Array<{
          id: string
          visaNumber: string
          visaName: string
          issueAt: string
          expiryAt: string
        }>
        seamanContracts: Array<{
          id: string
          hanseaticAssignmentId: number
          contractFrom: string
          contractUntil: string
        }>
        seamanRank: { name: string; sortOrder: number | null } | null
        seamanCommunications: Array<{ id: string; type: string; value: string | null }>
      }
      vessel: {
        id: string
        vesselScheduleSource: Types.VesselScheduleSource
        charterName: string
        imo: number
      } | null
    }>
  }>
}

export type LoadCrewChangeSeamenDetailsQueryVariables = Types.Exact<{
  take: Types.Scalars['Int']['input']
  vesselId: Types.Scalars['UUID']['input']
}>

export type LoadCrewChangeSeamenDetailsQuery = {
  crewChangeSeamenDetail: Array<{
    id: string
    crewHash: string
    signOnDate: string
    signOffDate: string
    contractEndAt: string
    seamanId: string
    role: Types.CrewChangeRole
    sourceId: string | null
    crewChangeLinkage: { isLinked: boolean; bucketId: string | null; isTemporary: boolean }
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
      flightBookings: Array<{
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        switchForIdenticalCheaperOfferAutomatically: boolean
        switchForSimilarCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        finalOffer: {
          amount: number
          currency: Types.Currency
          exchangeRate: { ratesToUsd: unknown } | null
        } | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }>
      preferredAirport: {
        isHomeAirport: boolean
        airport: {
          id: string
          alternativeIatas: Array<string>
          city: string
          countryAlpha2: string
          iata: string
          name: string
          timezoneOlson: string | null
        }
      } | null
      seamanBooks: Array<{
        id: string
        bookNumber: string
        issueAt: string | null
        expiryAt: string | null
        isFlagStateSeamanBook: boolean
        nationalityAlpha2: string | null
      }>
      seamanPassports: Array<{
        id: string
        passportNumber: string
        passportIssuePlace: string | null
        passportNationalityIso: string
        passportIssueAt: string
        passportExpiryAt: string
      }>
      seamanVisas: Array<{
        id: string
        visaNumber: string
        visaName: string
        issueAt: string
        expiryAt: string
      }>
      seamanContracts: Array<{
        id: string
        hanseaticAssignmentId: number
        contractFrom: string
        contractUntil: string
      }>
      seamanRank: { name: string; sortOrder: number | null } | null
      seamanCommunications: Array<{ id: string; type: string; value: string | null }>
    }
    vessel: {
      id: string
      vesselScheduleSource: Types.VesselScheduleSource
      charterName: string
      imo: number
    } | null
  }>
}

export type CreateCrewChangeMutationVariables = Types.Exact<{
  params: Types.CreateCrewChangeInput
}>

export type CreateCrewChangeMutation = { createCrewChange: string }

export type UpdateCrewChangeMutationVariables = Types.Exact<{
  params: Types.UpdateCrewChangeInput
}>

export type UpdateCrewChangeMutation = { updateCrewChange: string }

export type SearchCrewChangeSeamanDetailsQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input']
  rankId?: Types.InputMaybe<Types.Scalars['Int']['input']>
}>

export type SearchCrewChangeSeamanDetailsQuery = {
  searchCrewChangeSeamanDetails: Array<{
    id: string
    crewHash: string
    signOnDate: string
    signOffDate: string
    contractEndAt: string
    seamanId: string
    role: Types.CrewChangeRole
    sourceId: string | null
    crewChangeLinkage: { isLinked: boolean; bucketId: string | null; isTemporary: boolean }
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
      flightBookings: Array<{
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        switchForIdenticalCheaperOfferAutomatically: boolean
        switchForSimilarCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        finalOffer: {
          amount: number
          currency: Types.Currency
          exchangeRate: { ratesToUsd: unknown } | null
        } | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }>
      preferredAirport: {
        isHomeAirport: boolean
        airport: {
          id: string
          alternativeIatas: Array<string>
          city: string
          countryAlpha2: string
          iata: string
          name: string
          timezoneOlson: string | null
        }
      } | null
      seamanBooks: Array<{
        id: string
        bookNumber: string
        issueAt: string | null
        expiryAt: string | null
        isFlagStateSeamanBook: boolean
        nationalityAlpha2: string | null
      }>
      seamanPassports: Array<{
        id: string
        passportNumber: string
        passportIssuePlace: string | null
        passportNationalityIso: string
        passportIssueAt: string
        passportExpiryAt: string
      }>
      seamanVisas: Array<{
        id: string
        visaNumber: string
        visaName: string
        issueAt: string
        expiryAt: string
      }>
      seamanContracts: Array<{
        id: string
        hanseaticAssignmentId: number
        contractFrom: string
        contractUntil: string
      }>
      seamanRank: { name: string; sortOrder: number | null } | null
      seamanCommunications: Array<{ id: string; type: string; value: string | null }>
    }
    vessel: {
      id: string
      vesselScheduleSource: Types.VesselScheduleSource
      charterName: string
      imo: number
    } | null
  }>
}

export type GetLastDataSyncForSourceQueryVariables = Types.Exact<{
  type: Types.LastDataSyncType
}>

export type GetLastDataSyncForSourceQuery = { getLastDataSync: string }

export type GetConflictingSeamenForCrewChangeQueryVariables = Types.Exact<{
  bucketId: Types.Scalars['UUID']['input']
}>

export type GetConflictingSeamenForCrewChangeQuery = {
  getConflictingSeamenForCrewChange: Array<{
    id: string
    seamanId: string
    role: Types.CrewChangeRole
    crewHash: string
    active: boolean
    tenantId: string
    manningAgencyId: string | null
    sourceId: string | null
    isSearchDeferred: boolean
    seaman: {
      id: string
      firstName: string
      lastName: string
      nationalityIso: string
      rank: number | null
      birthday: string | null
      placeOfBirth: string | null
      firstVaccinationDate: string | null
      firstVaccinationBrand: string | null
      secondVaccinationDate: string | null
      secondVaccinationBrand: string | null
      updatedAt: string
      seamanBooks: Array<{
        id: string
        bookNumber: string
        issueAt: string | null
        expiryAt: string | null
        isFlagStateSeamanBook: boolean
        nationalityAlpha2: string | null
      }>
      seamanPassports: Array<{
        id: string
        passportNumber: string
        passportIssuePlace: string | null
        passportNationalityIso: string
        passportIssueAt: string
        passportExpiryAt: string
      }>
      seamanVisas: Array<{
        id: string
        visaNumber: string
        visaName: string
        issueAt: string
        expiryAt: string
      }>
      seamanContracts: Array<{
        id: string
        hanseaticAssignmentId: number
        contractFrom: string
        contractUntil: string
      }>
      seamanRank: { name: string; sortOrder: number | null } | null
      seamanCommunications: Array<{ id: string; type: string; value: string | null }>
    }
    conflictingCrewChanges: Array<{
      activeCrewOperatorType: Types.ActiveCrewOperatorType
      activeCrewSupervisorType: Types.ActiveCrewSupervisorType
      backupCrewOperatorId: string | null
      backupCrewSupervisorId: string | null
      primaryCrewSupervisorName: string | null
      backupCrewSupervisorName: string | null
      bucketId: string
      completed: boolean
      archived: boolean
      ETA: string
      ETB: string
      ETD: string
      vesselScheduleSource: Types.VesselScheduleSource
      manningAgencies: Array<string>
      numOffsigners: number
      numOnsigners: number
      totalNumSeafarersIncludingPromotions: number
      portAgents: Array<string>
      portName: string | null
      portTimezoneOlson: string | null
      primaryCrewOperatorId: string | null
      primaryCrewSupervisorId: string | null
      started: boolean | null
      vesselId: string
      vesselName: string
      isScheduleCancelled: boolean
      flightBookingsLTD: string | null
      bookedFlightBookings: number
      ticketedFlightBookings: number
      openFlightBookings: number
      primaryCrewOperatorName: string | null
      backupCrewOperatorName: string | null
    } | null>
  }>
}

export const LoadCrewChangeSeamenForVesselDocument = gql`
  query LoadCrewChangeSeamenForVessel($scheduleOrId: UUID, $vesselOrId: UUID!) {
    crewChangeSeamenForVessel(scheduleOrId: $scheduleOrId, vesselOrId: $vesselOrId) {
      id
      crewHash
      signOnDate
      signOffDate
      contractEndAt
      seamanId
      role
      sourceId
      crewChangeLinkage {
        isLinked
        bucketId
        isTemporary
      }
      seaman {
        ...CompleteSeaman
        flightBookings {
          ...CompleteFlightBooking
        }
        preferredAirport {
          airport {
            ...AirportFields
          }
          isHomeAirport
        }
      }
      vessel {
        ...VesselFields
      }
    }
  }
  ${CompleteSeamanFragmentDoc}
  ${CompleteFlightBookingFragmentDoc}
  ${AirportFieldsFragmentDoc}
  ${VesselFieldsFragmentDoc}
`

/**
 * __useLoadCrewChangeSeamenForVesselQuery__
 *
 * To run a query within a React component, call `useLoadCrewChangeSeamenForVesselQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCrewChangeSeamenForVesselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCrewChangeSeamenForVesselQuery({
 *   variables: {
 *      scheduleOrId: // value for 'scheduleOrId'
 *      vesselOrId: // value for 'vesselOrId'
 *   },
 * });
 */
export function useLoadCrewChangeSeamenForVesselQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCrewChangeSeamenForVesselQuery,
    LoadCrewChangeSeamenForVesselQueryVariables
  > &
    ({ variables: LoadCrewChangeSeamenForVesselQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LoadCrewChangeSeamenForVesselQuery,
    LoadCrewChangeSeamenForVesselQueryVariables
  >(LoadCrewChangeSeamenForVesselDocument, options)
}
export function useLoadCrewChangeSeamenForVesselLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCrewChangeSeamenForVesselQuery,
    LoadCrewChangeSeamenForVesselQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LoadCrewChangeSeamenForVesselQuery,
    LoadCrewChangeSeamenForVesselQueryVariables
  >(LoadCrewChangeSeamenForVesselDocument, options)
}
export function useLoadCrewChangeSeamenForVesselSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadCrewChangeSeamenForVesselQuery,
        LoadCrewChangeSeamenForVesselQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadCrewChangeSeamenForVesselQuery,
    LoadCrewChangeSeamenForVesselQueryVariables
  >(LoadCrewChangeSeamenForVesselDocument, options)
}
export type LoadCrewChangeSeamenForVesselQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenForVesselQuery
>
export type LoadCrewChangeSeamenForVesselLazyQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenForVesselLazyQuery
>
export type LoadCrewChangeSeamenForVesselSuspenseQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenForVesselSuspenseQuery
>
export type LoadCrewChangeSeamenForVesselQueryResult = Apollo.QueryResult<
  LoadCrewChangeSeamenForVesselQuery,
  LoadCrewChangeSeamenForVesselQueryVariables
>
export const LoadCrewChangeSeamenForRankDocument = gql`
  query LoadCrewChangeSeamenForRank($rankId: Int!) {
    crewChangeSeamenForRank(rankId: $rankId) {
      id
      crewHash
      signOnDate
      signOffDate
      contractEndAt
      seamanId
      role
      sourceId
      crewChangeLinkage {
        isLinked
        bucketId
        isTemporary
      }
      seaman {
        ...CompleteSeaman
        flightBookings {
          ...CompleteFlightBooking
        }
        preferredAirport {
          airport {
            ...AirportFields
          }
          isHomeAirport
        }
      }
      vessel {
        ...VesselFields
      }
    }
  }
  ${CompleteSeamanFragmentDoc}
  ${CompleteFlightBookingFragmentDoc}
  ${AirportFieldsFragmentDoc}
  ${VesselFieldsFragmentDoc}
`

/**
 * __useLoadCrewChangeSeamenForRankQuery__
 *
 * To run a query within a React component, call `useLoadCrewChangeSeamenForRankQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCrewChangeSeamenForRankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCrewChangeSeamenForRankQuery({
 *   variables: {
 *      rankId: // value for 'rankId'
 *   },
 * });
 */
export function useLoadCrewChangeSeamenForRankQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCrewChangeSeamenForRankQuery,
    LoadCrewChangeSeamenForRankQueryVariables
  > &
    ({ variables: LoadCrewChangeSeamenForRankQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LoadCrewChangeSeamenForRankQuery,
    LoadCrewChangeSeamenForRankQueryVariables
  >(LoadCrewChangeSeamenForRankDocument, options)
}
export function useLoadCrewChangeSeamenForRankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCrewChangeSeamenForRankQuery,
    LoadCrewChangeSeamenForRankQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LoadCrewChangeSeamenForRankQuery,
    LoadCrewChangeSeamenForRankQueryVariables
  >(LoadCrewChangeSeamenForRankDocument, options)
}
export function useLoadCrewChangeSeamenForRankSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadCrewChangeSeamenForRankQuery,
        LoadCrewChangeSeamenForRankQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadCrewChangeSeamenForRankQuery,
    LoadCrewChangeSeamenForRankQueryVariables
  >(LoadCrewChangeSeamenForRankDocument, options)
}
export type LoadCrewChangeSeamenForRankQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenForRankQuery
>
export type LoadCrewChangeSeamenForRankLazyQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenForRankLazyQuery
>
export type LoadCrewChangeSeamenForRankSuspenseQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenForRankSuspenseQuery
>
export type LoadCrewChangeSeamenForRankQueryResult = Apollo.QueryResult<
  LoadCrewChangeSeamenForRankQuery,
  LoadCrewChangeSeamenForRankQueryVariables
>
export const LoadCrewChangeSeamenForRanksDocument = gql`
  query LoadCrewChangeSeamenForRanks($rankIds: [Int!]!) {
    crewChangeSeamenForRanks(rankIds: $rankIds) {
      rankId
      crewChangeSeamen {
        sourceId
        id
        crewHash
        signOnDate
        signOffDate
        contractEndAt
        seamanId
        role
        crewChangeLinkage {
          isLinked
          bucketId
          isTemporary
        }
        seaman {
          ...CompleteSeaman
          flightBookings {
            ...CompleteFlightBooking
          }
          preferredAirport {
            airport {
              ...AirportFields
            }
            isHomeAirport
          }
        }
        vessel {
          ...VesselFields
        }
      }
    }
  }
  ${CompleteSeamanFragmentDoc}
  ${CompleteFlightBookingFragmentDoc}
  ${AirportFieldsFragmentDoc}
  ${VesselFieldsFragmentDoc}
`

/**
 * __useLoadCrewChangeSeamenForRanksQuery__
 *
 * To run a query within a React component, call `useLoadCrewChangeSeamenForRanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCrewChangeSeamenForRanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCrewChangeSeamenForRanksQuery({
 *   variables: {
 *      rankIds: // value for 'rankIds'
 *   },
 * });
 */
export function useLoadCrewChangeSeamenForRanksQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCrewChangeSeamenForRanksQuery,
    LoadCrewChangeSeamenForRanksQueryVariables
  > &
    ({ variables: LoadCrewChangeSeamenForRanksQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LoadCrewChangeSeamenForRanksQuery,
    LoadCrewChangeSeamenForRanksQueryVariables
  >(LoadCrewChangeSeamenForRanksDocument, options)
}
export function useLoadCrewChangeSeamenForRanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCrewChangeSeamenForRanksQuery,
    LoadCrewChangeSeamenForRanksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LoadCrewChangeSeamenForRanksQuery,
    LoadCrewChangeSeamenForRanksQueryVariables
  >(LoadCrewChangeSeamenForRanksDocument, options)
}
export function useLoadCrewChangeSeamenForRanksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadCrewChangeSeamenForRanksQuery,
        LoadCrewChangeSeamenForRanksQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadCrewChangeSeamenForRanksQuery,
    LoadCrewChangeSeamenForRanksQueryVariables
  >(LoadCrewChangeSeamenForRanksDocument, options)
}
export type LoadCrewChangeSeamenForRanksQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenForRanksQuery
>
export type LoadCrewChangeSeamenForRanksLazyQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenForRanksLazyQuery
>
export type LoadCrewChangeSeamenForRanksSuspenseQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenForRanksSuspenseQuery
>
export type LoadCrewChangeSeamenForRanksQueryResult = Apollo.QueryResult<
  LoadCrewChangeSeamenForRanksQuery,
  LoadCrewChangeSeamenForRanksQueryVariables
>
export const LoadCrewChangeSeamenDetailsDocument = gql`
  query LoadCrewChangeSeamenDetails($take: Int!, $vesselId: UUID!) {
    crewChangeSeamenDetail(take: $take, vesselId: $vesselId) {
      id
      crewHash
      signOnDate
      signOffDate
      contractEndAt
      seamanId
      role
      sourceId
      crewChangeLinkage {
        isLinked
        bucketId
        isTemporary
      }
      seaman {
        ...CompleteSeaman
        flightBookings {
          ...CompleteFlightBooking
        }
        preferredAirport {
          airport {
            ...AirportFields
          }
          isHomeAirport
        }
      }
      vessel {
        ...VesselFields
      }
    }
  }
  ${CompleteSeamanFragmentDoc}
  ${CompleteFlightBookingFragmentDoc}
  ${AirportFieldsFragmentDoc}
  ${VesselFieldsFragmentDoc}
`

/**
 * __useLoadCrewChangeSeamenDetailsQuery__
 *
 * To run a query within a React component, call `useLoadCrewChangeSeamenDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCrewChangeSeamenDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCrewChangeSeamenDetailsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      vesselId: // value for 'vesselId'
 *   },
 * });
 */
export function useLoadCrewChangeSeamenDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCrewChangeSeamenDetailsQuery,
    LoadCrewChangeSeamenDetailsQueryVariables
  > &
    ({ variables: LoadCrewChangeSeamenDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LoadCrewChangeSeamenDetailsQuery,
    LoadCrewChangeSeamenDetailsQueryVariables
  >(LoadCrewChangeSeamenDetailsDocument, options)
}
export function useLoadCrewChangeSeamenDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCrewChangeSeamenDetailsQuery,
    LoadCrewChangeSeamenDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LoadCrewChangeSeamenDetailsQuery,
    LoadCrewChangeSeamenDetailsQueryVariables
  >(LoadCrewChangeSeamenDetailsDocument, options)
}
export function useLoadCrewChangeSeamenDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadCrewChangeSeamenDetailsQuery,
        LoadCrewChangeSeamenDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LoadCrewChangeSeamenDetailsQuery,
    LoadCrewChangeSeamenDetailsQueryVariables
  >(LoadCrewChangeSeamenDetailsDocument, options)
}
export type LoadCrewChangeSeamenDetailsQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenDetailsQuery
>
export type LoadCrewChangeSeamenDetailsLazyQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenDetailsLazyQuery
>
export type LoadCrewChangeSeamenDetailsSuspenseQueryHookResult = ReturnType<
  typeof useLoadCrewChangeSeamenDetailsSuspenseQuery
>
export type LoadCrewChangeSeamenDetailsQueryResult = Apollo.QueryResult<
  LoadCrewChangeSeamenDetailsQuery,
  LoadCrewChangeSeamenDetailsQueryVariables
>
export const CreateCrewChangeDocument = gql`
  mutation CreateCrewChange($params: CreateCrewChangeInput!) {
    createCrewChange(params: $params)
  }
`
export type CreateCrewChangeMutationFn = Apollo.MutationFunction<
  CreateCrewChangeMutation,
  CreateCrewChangeMutationVariables
>

/**
 * __useCreateCrewChangeMutation__
 *
 * To run a mutation, you first call `useCreateCrewChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCrewChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCrewChangeMutation, { data, loading, error }] = useCreateCrewChangeMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateCrewChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCrewChangeMutation,
    CreateCrewChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCrewChangeMutation, CreateCrewChangeMutationVariables>(
    CreateCrewChangeDocument,
    options
  )
}
export type CreateCrewChangeMutationHookResult = ReturnType<typeof useCreateCrewChangeMutation>
export type CreateCrewChangeMutationResult = Apollo.MutationResult<CreateCrewChangeMutation>
export type CreateCrewChangeMutationOptions = Apollo.BaseMutationOptions<
  CreateCrewChangeMutation,
  CreateCrewChangeMutationVariables
>
export const UpdateCrewChangeDocument = gql`
  mutation UpdateCrewChange($params: UpdateCrewChangeInput!) {
    updateCrewChange(params: $params)
  }
`
export type UpdateCrewChangeMutationFn = Apollo.MutationFunction<
  UpdateCrewChangeMutation,
  UpdateCrewChangeMutationVariables
>

/**
 * __useUpdateCrewChangeMutation__
 *
 * To run a mutation, you first call `useUpdateCrewChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrewChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrewChangeMutation, { data, loading, error }] = useUpdateCrewChangeMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateCrewChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCrewChangeMutation,
    UpdateCrewChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCrewChangeMutation, UpdateCrewChangeMutationVariables>(
    UpdateCrewChangeDocument,
    options
  )
}
export type UpdateCrewChangeMutationHookResult = ReturnType<typeof useUpdateCrewChangeMutation>
export type UpdateCrewChangeMutationResult = Apollo.MutationResult<UpdateCrewChangeMutation>
export type UpdateCrewChangeMutationOptions = Apollo.BaseMutationOptions<
  UpdateCrewChangeMutation,
  UpdateCrewChangeMutationVariables
>
export const SearchCrewChangeSeamanDetailsDocument = gql`
  query SearchCrewChangeSeamanDetails($search: String!, $rankId: Int) {
    searchCrewChangeSeamanDetails(search: $search, rankId: $rankId) {
      id
      crewHash
      signOnDate
      signOffDate
      contractEndAt
      seamanId
      role
      sourceId
      crewChangeLinkage {
        isLinked
        bucketId
        isTemporary
      }
      seaman {
        ...CompleteSeaman
        flightBookings {
          ...CompleteFlightBooking
        }
        preferredAirport {
          airport {
            ...AirportFields
          }
          isHomeAirport
        }
      }
      vessel {
        ...VesselFields
      }
    }
  }
  ${CompleteSeamanFragmentDoc}
  ${CompleteFlightBookingFragmentDoc}
  ${AirportFieldsFragmentDoc}
  ${VesselFieldsFragmentDoc}
`

/**
 * __useSearchCrewChangeSeamanDetailsQuery__
 *
 * To run a query within a React component, call `useSearchCrewChangeSeamanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCrewChangeSeamanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCrewChangeSeamanDetailsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      rankId: // value for 'rankId'
 *   },
 * });
 */
export function useSearchCrewChangeSeamanDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchCrewChangeSeamanDetailsQuery,
    SearchCrewChangeSeamanDetailsQueryVariables
  > &
    ({ variables: SearchCrewChangeSeamanDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchCrewChangeSeamanDetailsQuery,
    SearchCrewChangeSeamanDetailsQueryVariables
  >(SearchCrewChangeSeamanDetailsDocument, options)
}
export function useSearchCrewChangeSeamanDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchCrewChangeSeamanDetailsQuery,
    SearchCrewChangeSeamanDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchCrewChangeSeamanDetailsQuery,
    SearchCrewChangeSeamanDetailsQueryVariables
  >(SearchCrewChangeSeamanDetailsDocument, options)
}
export function useSearchCrewChangeSeamanDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SearchCrewChangeSeamanDetailsQuery,
        SearchCrewChangeSeamanDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SearchCrewChangeSeamanDetailsQuery,
    SearchCrewChangeSeamanDetailsQueryVariables
  >(SearchCrewChangeSeamanDetailsDocument, options)
}
export type SearchCrewChangeSeamanDetailsQueryHookResult = ReturnType<
  typeof useSearchCrewChangeSeamanDetailsQuery
>
export type SearchCrewChangeSeamanDetailsLazyQueryHookResult = ReturnType<
  typeof useSearchCrewChangeSeamanDetailsLazyQuery
>
export type SearchCrewChangeSeamanDetailsSuspenseQueryHookResult = ReturnType<
  typeof useSearchCrewChangeSeamanDetailsSuspenseQuery
>
export type SearchCrewChangeSeamanDetailsQueryResult = Apollo.QueryResult<
  SearchCrewChangeSeamanDetailsQuery,
  SearchCrewChangeSeamanDetailsQueryVariables
>
export const GetLastDataSyncForSourceDocument = gql`
  query GetLastDataSyncForSource($type: LastDataSyncType!) {
    getLastDataSync(type: $type)
  }
`

/**
 * __useGetLastDataSyncForSourceQuery__
 *
 * To run a query within a React component, call `useGetLastDataSyncForSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastDataSyncForSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastDataSyncForSourceQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetLastDataSyncForSourceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastDataSyncForSourceQuery,
    GetLastDataSyncForSourceQueryVariables
  > &
    ({ variables: GetLastDataSyncForSourceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLastDataSyncForSourceQuery, GetLastDataSyncForSourceQueryVariables>(
    GetLastDataSyncForSourceDocument,
    options
  )
}
export function useGetLastDataSyncForSourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastDataSyncForSourceQuery,
    GetLastDataSyncForSourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLastDataSyncForSourceQuery, GetLastDataSyncForSourceQueryVariables>(
    GetLastDataSyncForSourceDocument,
    options
  )
}
export function useGetLastDataSyncForSourceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetLastDataSyncForSourceQuery,
        GetLastDataSyncForSourceQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetLastDataSyncForSourceQuery,
    GetLastDataSyncForSourceQueryVariables
  >(GetLastDataSyncForSourceDocument, options)
}
export type GetLastDataSyncForSourceQueryHookResult = ReturnType<
  typeof useGetLastDataSyncForSourceQuery
>
export type GetLastDataSyncForSourceLazyQueryHookResult = ReturnType<
  typeof useGetLastDataSyncForSourceLazyQuery
>
export type GetLastDataSyncForSourceSuspenseQueryHookResult = ReturnType<
  typeof useGetLastDataSyncForSourceSuspenseQuery
>
export type GetLastDataSyncForSourceQueryResult = Apollo.QueryResult<
  GetLastDataSyncForSourceQuery,
  GetLastDataSyncForSourceQueryVariables
>
export const GetConflictingSeamenForCrewChangeDocument = gql`
  query GetConflictingSeamenForCrewChange($bucketId: UUID!) {
    getConflictingSeamenForCrewChange(bucketId: $bucketId) {
      id
      seamanId
      role
      crewHash
      seaman {
        ...CompleteSeaman
      }
      active
      tenantId
      manningAgencyId
      sourceId
      isSearchDeferred
      conflictingCrewChanges {
        ...CrewChangeOverviewFields
      }
    }
  }
  ${CompleteSeamanFragmentDoc}
  ${CrewChangeOverviewFieldsFragmentDoc}
`

/**
 * __useGetConflictingSeamenForCrewChangeQuery__
 *
 * To run a query within a React component, call `useGetConflictingSeamenForCrewChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConflictingSeamenForCrewChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConflictingSeamenForCrewChangeQuery({
 *   variables: {
 *      bucketId: // value for 'bucketId'
 *   },
 * });
 */
export function useGetConflictingSeamenForCrewChangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConflictingSeamenForCrewChangeQuery,
    GetConflictingSeamenForCrewChangeQueryVariables
  > &
    (
      | { variables: GetConflictingSeamenForCrewChangeQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetConflictingSeamenForCrewChangeQuery,
    GetConflictingSeamenForCrewChangeQueryVariables
  >(GetConflictingSeamenForCrewChangeDocument, options)
}
export function useGetConflictingSeamenForCrewChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConflictingSeamenForCrewChangeQuery,
    GetConflictingSeamenForCrewChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetConflictingSeamenForCrewChangeQuery,
    GetConflictingSeamenForCrewChangeQueryVariables
  >(GetConflictingSeamenForCrewChangeDocument, options)
}
export function useGetConflictingSeamenForCrewChangeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetConflictingSeamenForCrewChangeQuery,
        GetConflictingSeamenForCrewChangeQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetConflictingSeamenForCrewChangeQuery,
    GetConflictingSeamenForCrewChangeQueryVariables
  >(GetConflictingSeamenForCrewChangeDocument, options)
}
export type GetConflictingSeamenForCrewChangeQueryHookResult = ReturnType<
  typeof useGetConflictingSeamenForCrewChangeQuery
>
export type GetConflictingSeamenForCrewChangeLazyQueryHookResult = ReturnType<
  typeof useGetConflictingSeamenForCrewChangeLazyQuery
>
export type GetConflictingSeamenForCrewChangeSuspenseQueryHookResult = ReturnType<
  typeof useGetConflictingSeamenForCrewChangeSuspenseQuery
>
export type GetConflictingSeamenForCrewChangeQueryResult = Apollo.QueryResult<
  GetConflictingSeamenForCrewChangeQuery,
  GetConflictingSeamenForCrewChangeQueryVariables
>
