import clsx from 'clsx'
import { ReactNode, SVGProps } from 'react'

import CheckmarkCircleIcon from '../Icon/CheckmarkCircleIcon'
import ErrorIcon from '../Icon/ErrorIcon'
import InfoIcon from '../Icon/InfoIcon'
import WarningIcon from '../Icon/WarningIcon'

type AlertVariant = 'info' | 'success' | 'danger' | 'warning' | 'neutral'

export interface AlertProps {
  variant?: AlertVariant
  title?: string | ReactNode
  children?: ReactNode
  actions?: ReactNode // @todo - Scope down to button once button is in design system
  icon?: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | null // Set to null to hide icon, set to icon to override, leave out to use default
  className?: string
}

const defaultIconForVariant: Record<AlertVariant, (props: SVGProps<SVGSVGElement>) => JSX.Element> =
  {
    info: InfoIcon,
    success: CheckmarkCircleIcon,
    danger: ErrorIcon,
    warning: WarningIcon,
    neutral: InfoIcon,
  }

function Alert({
  variant = 'info',
  children,
  icon,
  title,
  actions,
  className,
}: AlertProps): JSX.Element {
  const AlertIcon = icon === null ? null : icon ?? defaultIconForVariant[variant]

  return (
    <div
      role="alertdialog"
      className={clsx(
        'space-y-3 sm:space-y-0 sm:flex items-center p-3 text-sm leading-none border rounded-lg text-gray-800',
        variant === 'success' && 'bg-green-50  border-green-200',
        variant === 'danger' && 'bg-red-50  border-red-200',
        variant === 'warning' && 'bg-yellow-50 border-yellow-200',
        variant === 'info' && 'bg-blue-50  border-blue-200',
        variant === 'neutral' && 'bg-neutral-50  border-neutral-200',
        className
      )}
    >
      {AlertIcon && (
        <span
          className={clsx(
            'block w-5 h-5 sm:mr-3 grow-0 shrink-0',
            variant === 'success' && 'text-green-600',
            variant === 'danger' && 'text-red-600',
            variant === 'warning' && 'text-yellow-500',
            variant === 'info' && 'text-brand'
          )}
        >
          <AlertIcon />
        </span>
      )}
      <div className={clsx('space-y-2 sm:mr-6')}>
        {title && <h3 className="font-semibold text-gray-900">{title}</h3>}
        {children && <div className="space-y-2 text-sm">{children}</div>}
      </div>
      {actions && (
        <div className="flex sm:flex-row-reverse sm:space-x-reverse space-x-1.5 ml-auto self-center">
          {actions}
        </div>
      )}
    </div>
  )
}

export default Alert
