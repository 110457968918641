import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { GraphQLFormattedError } from 'graphql'
import { createClient } from 'graphql-ws'
import { isObjectLike } from 'lodash'
import router from 'next/router'
import { globalErrorHandler } from 'utils/errorHandling'
import traverseObject from 'utils/traverse-object'
import { ALLOWED_PUBLIC_PATHS } from '~components/RouteGuard/RouteGuard'
import { isDev } from '../utils/checkEnv'
import { prefix } from './fetch-api'
import { UserRole } from './types.generated'

export enum ErrorCode {
  Unknown = 'Unknown',
  BadRequest = 'BAD_REQUEST',
  NotFound = '404',
  BadUserInput = 'BAD_USER_INPUT',
  Unauthenticated = 'UNAUTHENTICATED',
  ServerError = 'INTERNAL_SERVER_ERROR',
}

export function getErrorCode(error?: { graphQLErrors?: ReadonlyArray<GraphQLFormattedError> }) {
  // we take first error as we going to use only one operation per request. Should be changed if not
  const graphQLErrors = error?.graphQLErrors
  if (Object.values(ErrorCode).includes(graphQLErrors?.[0]?.extensions?.code as ErrorCode)) {
    return graphQLErrors?.[0]?.extensions?.code as ErrorCode
  }
  return graphQLErrors?.[0] ? ErrorCode.Unknown : null
}

const userEnumLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((res) => {
    if (!res.data) {
      return res
    }
    traverseObject(res.data, (v) => {
      if (isObjectLike(v) && ['SafePartialUser', 'User'].includes(v.__typename) && v.role) {
        // eslint-disable-next-line no-param-reassign
        v.role = UserRole[v.role as keyof typeof UserRole]
      }
    })
    return res
  })
})

const errorLink: ApolloLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    const errorCode = getErrorCode({ graphQLErrors })

    graphQLErrors.forEach((error) => {
      globalErrorHandler(new Error(`Error: ${error.message}`), {
        errorCode,
        isAsync: true,
        notifyUser: false,
        operationName: operation.operationName,
      })
    })
  }

  if (networkError) {
    globalErrorHandler(new Error(`Network error: ${networkError.message}`), {
      isAsync: true,
      notifyUser: false,
    })
  }
})

const logoutLink = onError((error) => {
  const errorCode = getErrorCode(error)
  if (errorCode === ErrorCode.Unauthenticated) {
    // todo: could be custom link with proper awaiting of async operations
    const cleanedPath = router.asPath.split('?')[0]
    if (!ALLOWED_PUBLIC_PATHS.includes(cleanedPath)) {
      router.push('/unauthenticated')
    }
  }
})

const connectToDevTools = isDev()

const getTokenFromCookie = async () => {
  try {
    const response = await fetch('/api/auth', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error(`Failed to fetch token: ${response.statusText}`)
    }

    const { token } = await response.json()
    return token
  } catch (error) {
    console.error('Error fetching auth token:', error)
    throw error
  }
}

export const apiCache = new InMemoryCache({
  typePolicies: {
    CrewChangeOverview: { keyFields: ['bucketId'] },
    CrewChange: { keyFields: ['bucketId'] },
    Airport: { keyFields: ['iata'] },
    PortPlanningScenario: {
      keyFields: ['id'],
    },
    Subscription: {
      fields: {
        crewChangeUpdated: {
          merge: true,
        },
        portPlanningScenarioUpdated: {
          merge: true,
        },
      },
    },
    Query: {
      fields: {
        airport(_, { args, toReference }) {
          return (
            (args &&
              toReference({
                __typename: 'Airport',
                iata: args.iata,
              })) ??
            undefined
          )
        },
        booking(_, { args, toReference }) {
          return (
            (args &&
              toReference({
                __typename: 'FlightBooking',
                id: args.id,
              })) ??
            undefined
          )
        },
        bookings(_, { args, toReference }) {
          return (
            args?.ids.map((id: string) =>
              toReference({
                __typename: 'FlightBooking',
                id,
              })
            ) ?? undefined
          )
        },
        crewChangeDocuments: {
          merge: false,
        },
      },
    },
  },
})

export const getApolloLink = () => {
  const httpLink = ApolloLink.from([
    removeTypenameFromVariables(),
    userEnumLink,
    errorLink,
    logoutLink,
    createUploadLink({
      uri: `${prefix}/graphql`,
      credentials: 'include',
    }),
  ])

  // On NextJS, we need to ensure that the subscription is coming from the client, not the server
  const isWindow = typeof window !== 'undefined'
  if (!isWindow) {
    return httpLink
  }

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.NEXT_PUBLIC_API_WS_URL ?? '',
      connectionParams: async () => {
        try {
          const token = await getTokenFromCookie()
          return { authToken: `Bearer ${token}` }
        } catch (error) {
          console.error('Failed to get auth token for WebSocket connection:', error)
          throw error
        }
      },
      shouldRetry: (error) => {
        console.log('WebSocket connection error, attempting retry:', error)
        return true
      },
      retryAttempts: 3,
      keepAlive: 10000,
      on: {
        connected: () => console.log('WebSocket connected successfully'),
        error: (error: unknown) => {
          console.error('WebSocket error:', error)
          globalErrorHandler(error instanceof Error ? error : new Error(String(error)), {
            isAsync: true,
            notifyUser: true,
          })
        },
        closed: () => console.log('WebSocket connection closed'),
      },
    })
  )

  const splitLink =
    isWindow && wsLink != null
      ? split(
          ({ query }) => {
            try {
              const definition = getMainDefinition(query)
              return (
                definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
              )
            } catch (error) {
              console.error('Error in split link:', error)
              return false // Fallback to HTTP link on error
            }
          },
          wsLink,
          httpLink
        )
      : httpLink

  return splitLink
}

const client = new ApolloClient({
  cache: apiCache,
  connectToDevTools,
  link: getApolloLink(),
  credentials: 'include',
})

export default client
